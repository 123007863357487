import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getTooltip } from "@/util/chartOptions";
import { getColorfromCssVar } from "@/util/colorUtils";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let DashBoardDoughnutChart = class DashBoardDoughnutChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "nameValues", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "labelTooltip", void 0);
    _defineProperty(this, "labelTooltipPlural", void 0);
    _defineProperty(this, "datasets", []);
    _defineProperty(this, "names", []);
    _defineProperty(this, "loading", false);
  }
  mounted() {
    Chart.register(...registerables);
    setTimeout(() => {
      this.loading = true;
    }, 10);
  }
  onDataChange() {
    if (this.nameValues) {
      this.datasets = [{
        label: this.title,
        data: this.nameValues.map(nv => nv.value),
        backgroundColor: this.colors
      }];
      this.names = this.nameValues.map(nv => nv.name);
    }
  }
  get dataGraph() {
    return {
      labels: this.names,
      datasets: this.datasets
    };
  }
  get options() {
    return {
      plugins: {
        tooltip: getTooltip("nearest", this.title, this.$t, false, this.labelTooltip, this.labelTooltipPlural, false),
        datalabels: {
          font: {
            family: "Nunito Sans",
            size: "18px",
            weight: "900"
          },
          color: [getColorfromCssVar('white'), getColorfromCssVar('white'), getColorfromCssVar('white'), getColorfromCssVar('white'), getColorfromCssVar('dark'), getColorfromCssVar('dark')]
        },
        legend: {
          onClick: function (event) {
            event.stopPropagation();
          }
        }
      }
    };
  }
  get plugins() {
    return [ChartDataLabels];
  }
  get colors() {
    return [getColorfromCssVar('primary'), getColorfromCssVar('black'), getColorfromCssVar('dark'), getColorfromCssVar('light2'), getColorfromCssVar('light1'), getColorfromCssVar('light')];
  }
};
__decorate([Prop({
  required: true
})], DashBoardDoughnutChart.prototype, "nameValues", void 0);
__decorate([Prop({
  default: ""
})], DashBoardDoughnutChart.prototype, "title", void 0);
__decorate([Prop({
  default: ""
})], DashBoardDoughnutChart.prototype, "labelTooltip", void 0);
__decorate([Prop({
  default: ""
})], DashBoardDoughnutChart.prototype, "labelTooltipPlural", void 0);
__decorate([Watch("nameValues")], DashBoardDoughnutChart.prototype, "onDataChange", null);
DashBoardDoughnutChart = __decorate([Component({
  components: {
    DoughnutChart
  }
})], DashBoardDoughnutChart);
export default DashBoardDoughnutChart;