import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { SEARCH_SORT } from "@/models/speachSearchResult.model";
import SearchCategoryTitle from "@/components/search/SearchCategoryTitle.vue";
import Loader from "@/components/loader/Loader.vue";
import WSelect from "@/components/form/w-select.vue";
import StandardSpeachCard from "@/components/card/StandardSpeachCard.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let SpeachSearchResultModel = class SpeachSearchResultModel extends Vue {
  constructor(...args) {
    super(...args);
    // TODO map an speach Model
    _defineProperty(this, "speachesFound", void 0);
    _defineProperty(this, "totalNumberOfSpeachesFound", void 0);
    _defineProperty(this, "availableLanguages", void 0);
    _defineProperty(this, "sort", void 0);
    _defineProperty(this, "langFilter", void 0);
  }
  get sortOptions() {
    return [{
      id: 0,
      text: this.$t("search.select.by.relevant"),
      filter: SEARCH_SORT.RELEVANT
    }, {
      id: 1,
      text: this.$t("search.select.by.date"),
      filter: SEARCH_SORT.RECENT
    }, {
      id: 2,
      text: this.$t("search.select.by.title"),
      filter: SEARCH_SORT.TITRE
    }];
  }
  get langFilterOptions() {
    return [{
      id: 0,
      text: this.$t("lang.select.all"),
      filter: 0
    }].concat(this.availableLanguages.map(lang => ({
      id: lang.id,
      text: lang.language,
      filter: lang.id
    })));
  }
  get sortIndex() {
    const sortValue = this.sort;
    const value = this.sortOptions.find(element => element.filter === sortValue);
    if (value) {
      return value.id;
    } else {
      return 0;
    }
  }
  filterSpeachLang(param) {
    this.$emit("selectlangfilter", {
      filter: param
    });
  }
  sortSpeachSearch(id) {
    const value = this.sortOptions.find(el => {
      return el.id === id;
    }).filter;
    if (value !== this.sort) this.$emit("sortspeachsearch", {
      sort: value
    });
  }
  resetInfiniteLoader() {
    if (this.$refs.infiniteLoader) {
      // @ts-ignore
      this.$refs.infiniteLoader.$emit("$InfiniteLoading:reset", {
        target: this.$refs.infiniteLoader
      });
    }
  }
  getClass(id) {
    return "anime" + (id + 1) % 12;
  }
};
__decorate([Prop({
  default: []
})], SpeachSearchResultModel.prototype, "speachesFound", void 0);
__decorate([Prop({
  default: 0
})], SpeachSearchResultModel.prototype, "totalNumberOfSpeachesFound", void 0);
__decorate([Prop({
  default: []
})], SpeachSearchResultModel.prototype, "availableLanguages", void 0);
__decorate([Prop({
  default: SEARCH_SORT.RELEVANT
})], SpeachSearchResultModel.prototype, "sort", void 0);
__decorate([Prop({
  default: ''
})], SpeachSearchResultModel.prototype, "langFilter", void 0);
SpeachSearchResultModel = __decorate([Component({
  components: {
    StandardSpeachCard,
    WSelect,
    Loader,
    SearchCategoryTitle
  }
})], SpeachSearchResultModel);
export default SpeachSearchResultModel;