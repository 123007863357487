import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import UserCell from "@/components/user/UserCell.vue";
import axios from "axios";
import { userToUserInfos } from "@/util/userUtils";
let WSelectUsers = class WSelectUsers extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "enabled", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "required", void 0);
    _defineProperty(this, "valueKey", void 0);
    _defineProperty(this, "labelKey", void 0);
    _defineProperty(this, "multiple", void 0);
    _defineProperty(this, "collapseTags", void 0);
    _defineProperty(this, "classClickFilter", void 0);
    _defineProperty(this, "dataTest", void 0);
    _defineProperty(this, "roleFilter", void 0);
    _defineProperty(this, "authors", []);
    _defineProperty(this, "realValue", null);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
  }
  mounted() {
    this.realValue = this.value;
  }
  get placeholderDisplay() {
    if (Array.isArray(this.realValue) || this.realValue === null) {
      return this.placeholder;
    } else {
      return this.realValue.label;
    }
  }
  updateUsersList(query) {
    if (query !== '') {
      this.loading = true;
      axios.get(this.BASE_API_SPEACHES + '/authors', {
        params: {
          search: query,
          role: this.roleFilter
        }
      }).then(response => {
        this.loading = false;
        this.authors = response.data.map(user => userToUserInfos(user));
      });
    } else {
      this.authors = [];
    }
  }
  onChangeValue() {
    this.$emit('input', this.realValue);
  }
  isSelected(item) {
    if (this.realValue === null) {
      return false;
    } else if (Array.isArray(this.realValue)) {
      return this.realValue.filter(currentItem => currentItem.id === item.id).length > 0;
    } else {
      return this.realValue.id === item.id;
    }
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
};
__decorate([Prop({
  default: ''
})], WSelectUsers.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], WSelectUsers.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WSelectUsers.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WSelectUsers.prototype, "placeholder", void 0);
__decorate([Prop({
  default: ''
})], WSelectUsers.prototype, "label", void 0);
__decorate([Prop({
  default: null
})], WSelectUsers.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WSelectUsers.prototype, "required", void 0);
__decorate([Prop({
  default: "value"
})], WSelectUsers.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "label"
})], WSelectUsers.prototype, "labelKey", void 0);
__decorate([Prop({
  default: false
})], WSelectUsers.prototype, "multiple", void 0);
__decorate([Prop({
  default: false
})], WSelectUsers.prototype, "collapseTags", void 0);
__decorate([Prop({
  default: ''
})], WSelectUsers.prototype, "classClickFilter", void 0);
__decorate([Prop()], WSelectUsers.prototype, "dataTest", void 0);
__decorate([Prop({
  default: null
})], WSelectUsers.prototype, "roleFilter", void 0);
__decorate([Watch('realValue')], WSelectUsers.prototype, "onChangeValue", null);
__decorate([Watch('value')], WSelectUsers.prototype, "onChangePropValue", null);
WSelectUsers = __decorate([Component({
  components: {
    UserCell,
    Icon
  },
  emits: ['input']
})], WSelectUsers);
export default WSelectUsers;