import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import WDialog from "@/components/wrappers/w-dialog.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import delay from "delay";
import Icon from "@/components/icon/Icon.vue";
// @ts-ignore
import RecordRTC from 'recordrtc';
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
let MediaRecorder = class MediaRecorder extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "countdown", void 0);
    _defineProperty(this, "audioOnly", void 0);
    _defineProperty(this, "withButton", void 0);
    _defineProperty(this, "currentCountDown", 0);
    _defineProperty(this, "recordRTC", null);
    _defineProperty(this, "isRecording", false);
    _defineProperty(this, "timeRecorded", 0);
    _defineProperty(this, "currentStream", null);
    _defineProperty(this, "cancelAsked", false);
    _defineProperty(this, "isPause", false);
  }
  mounted() {
    this.currentCountDown = this.countdown;
  }
  async startRecording() {
    this.currentCountDown = this.countdown;
    this.cancelAsked = false;
    // Get authorisation
    try {
      const mic = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      if (!mic) {
        this.onError(this.$t('mediaRecorder.mic.error'));
      }
    } catch (e) {
      this.onError(this.$t('mediaRecorder.mic.error'));
    }
    for (let i = this.countdown; i > -1; i--) {
      this.currentCountDown = i;
      this.onCountDown(this.currentCountDown);
      await delay(1000);
      if (this.cancelAsked) {
        this.cancelAsked = false;
        return;
      }
    }
    if (this.cancelAsked) {
      this.cancelAsked = false;
      return;
    }
    this.record();
  }
  async record() {
    this.currentStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      }
    });
    this.recordRTC = new RecordRTC(this.currentStream, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=opus',
      disableLogs: true,
      audioBitsPerSecond: 64000
    });
    this.recordRTC.startRecording();
    this.isRecording = true;
    this.onStart();
    let timeStart = new Date().getTime();
    this.timeRecorded = 0;
    while (this.isRecording) {
      timeStart = new Date().getTime();
      await delay(500);
      if (!this.isPause) {
        this.timeRecorded += new Date().getTime() - timeStart;
        this.onTimeRecord(this.timeRecorded);
      }
    }
  }
  pauseRecording() {
    this.recordRTC.pauseRecording();
    this.isPause = true;
  }
  resumeRecording() {
    this.recordRTC.resumeRecording();
    this.isPause = false;
  }
  async stopRecording() {
    if (this.isRecording) {
      var _this$currentStream;
      if (this.isPause) {
        this.resumeRecording();
        await delay(100);
      }
      this.recordRTC.stopRecording(async () => {
        let blob = this.recordRTC.getBlob();
        this.recordRTC.destroy();
        this.onBlobRecorded(null, blob);
      });
      (_this$currentStream = this.currentStream) === null || _this$currentStream === void 0 || _this$currentStream.getTracks().forEach(track => track.stop());
      this.currentStream = null;
      this.isRecording = false;
    }
  }
  cancel() {
    this.cancelAsked = true;
  }
  onBlobRecorded(event, blob) {
    return blob;
  }
  onError(message) {
    WElMessage.showErrorMessage(message);
    return message;
  }
  onCountDown(counter) {
    return counter;
  }
  onTimeRecord(time) {
    return time;
  }
  onStart() {
    return;
  }
};
__decorate([Prop({
  default: 3
})], MediaRecorder.prototype, "countdown", void 0);
__decorate([Prop({
  default: false
})], MediaRecorder.prototype, "audioOnly", void 0);
__decorate([Prop({
  default: true
})], MediaRecorder.prototype, "withButton", void 0);
__decorate([Emit], MediaRecorder.prototype, "onBlobRecorded", null);
__decorate([Emit], MediaRecorder.prototype, "onError", null);
__decorate([Emit], MediaRecorder.prototype, "onCountDown", null);
__decorate([Emit], MediaRecorder.prototype, "onTimeRecord", null);
__decorate([Emit], MediaRecorder.prototype, "onStart", null);
MediaRecorder = __decorate([Component({
  components: {
    Icon,
    StandardButton,
    WDialog
  }
})], MediaRecorder);
export default MediaRecorder;