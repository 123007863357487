import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { ElMessage } from "element-plus";
import axios from "axios";
import { Component, Prop, Vue } from "vue-facing-decorator";
let ExportCsv = class ExportCsv extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "superAdminAccess", void 0);
    _defineProperty(this, "enableScroll", void 0);
    _defineProperty(this, "listTemplates", []);
  }
  mounted() {
    this.getListTemplates();
  }
  get maxScroll() {
    if (this.enableScroll) {
      return '365px';
    } else {
      return '-1';
    }
  }
  async getListTemplates() {
    const templates = await axios.get('/administration/listCsvTemplates', {
      params: {
        dataType: "json",
        superAdminAccess: this.superAdminAccess
      }
    });
    this.listTemplates = templates.data;
    return this.listTemplates;
  }
  labelTemplate(template) {
    let label = '';
    switch (this.$i18n.locale) {
      case "fr":
        label = template.labelFr;
        break;
      case "es":
        label = template.labelEs;
        break;
      case "de":
        label = template.labelDe;
        break;
      default:
        label = template.labelEn;
        break;
    }
    if (label === '') {
      label = template.templateName;
    }
    return label;
  }
  download(template) {
    axios.post('/administration/launchMultipleExportCsv', {
      dataType: "json",
      templateNames: [template.templateName]
    }).then(result => {
      this.displaySuccessMessage(this.$t("admin.dashboard.download.alertSuccess"));
      this.$emit("closePopup");
    }, reason => console.log(reason));
  }
  downloadAll() {
    axios.post('/administration/launchMultipleExportCsv', {
      dataType: "json",
      templateNames: this.listTemplates.map(templateToCheck => templateToCheck.templateName)
    }).then(result => {
      this.displaySuccessMessage(this.$t("admin.dashboard.download.alertSuccess"));
      this.$emit("closePopup");
    }, reason => console.log(reason));
  }
  displaySuccessMessage(msg, withCloseOption = true) {
    ElMessage({
      message: msg,
      type: 'success',
      showClose: withCloseOption
    });
  }
};
__decorate([Prop({
  default: false
})], ExportCsv.prototype, "superAdminAccess", void 0);
__decorate([Prop({
  default: false
})], ExportCsv.prototype, "enableScroll", void 0);
ExportCsv = __decorate([Component({
  emits: ['closePopup']
})], ExportCsv);
export default ExportCsv;