import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading';
import GridLayout from "@/components/layout/GridLayout.vue";
import Icon from "@/components/icon/Icon.vue";
import SearchBarSearchField from "@/components/search/SearchBarSearchField.vue";
import { SEARCH_SORT, SpeachSearchResultModel } from "@/models/speachSearchResult.model";
import Loader from "@/components/loader/Loader.vue";
import UserSearchResult from "@/components/search/UserSearchResult.vue";
import { UserSearchResultModel } from "@/models/userSearchResult.model";
import SpeachSearchResult from "@/components/search/SpeachSearchResult.vue";
import ImageBackground from "@/components/layout/ImageBackground.vue";
import { debounce } from "lodash";
import { Component, Prop, Vue } from "vue-facing-decorator";
/** Composant pour la barre de recherche du Header de l'application */
let SearchBar = class SearchBar extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "searchUser", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "overlayOpen", false);
    _defineProperty(this, "searchTerm", '');
    _defineProperty(this, "searching", false);
    _defineProperty(this, "speaches", new SpeachSearchResultModel());
    _defineProperty(this, "users", new UserSearchResultModel());
    _defineProperty(this, "axiosCancelTokenSource", axios.CancelToken.source());
    _defineProperty(this, "searchFinished", false);
    _defineProperty(this, "empty", true);
    _defineProperty(this, "initialSearchTerm", null);
    _defineProperty(this, "throttledHandleSearchTermChange", debounce(this.handleSearchTermChange, 500));
  }
  get numberOfSpeachesDisplayed() {
    return this.speaches.speachesFound.length;
  }
  created() {
    let queryString = window.location.search;
    // S'il existe on lance la recherche
    if (queryString.includes("?searchTerm=")) {
      this.initialSearchTerm = decodeURIComponent(queryString.replace("?searchTerm=", ""));
    }
  }
  filterSearch() {
    this.speaches.currentSpeachPage = 1;
    this.searchSpeaches(this.searchTerm, this.speaches.currentSpeachPage, this.speaches.sort, this.speaches.langFilter).then(speachSearchResponse => {
      this.handleSpeachSearchResponse(speachSearchResponse);
    });
  }
  infiniteHandler($state) {
    if (this.numberOfSpeachesDisplayed < this.speaches.totalNumberOfSpeachesFound) {
      this.executeSearch().then(() => {
        $state.loaded();
        if (this.numberOfSpeachesDisplayed === this.speaches.totalNumberOfSpeachesFound) {
          $state.complete();
        }
      });
    } else {
      $state.loaded();
      $state.complete();
    }
  }
  handleSearchTermChange(term) {
    this.updateAdressBar(term);
    this.overlayOpen = true;
    this.searchFinished = false;
    this.axiosCancelTokenSource.cancel("Cancelling inflight requests because of search term change");
    this.axiosCancelTokenSource = axios.CancelToken.source();
    this.searchTerm = term;
    this.speaches.currentSpeachPage = 0;
    this.users.page = 1;
    this.users.startSlide = 0;
    if (this.searchTerm == "") {
      this.speaches.sort = SEARCH_SORT.RECENT;
    }
    if (this.searchTerm) {
      this.scrollToTop();
      this.searching = true;
      this.executeSearch().then(() => {
        this.searching = false;
        if (this.$refs.speachSearchResult) {
          // @ts-ignore
          this.$refs.speachSearchResult.resetInfiniteLoader();
        }
      });
    } else {
      this.closeSearch();
    }
  }
  updateAdressBar(term) {
    if (term) {
      window.history.replaceState("", "", "/?searchTerm=" + term);
    } else {
      window.history.replaceState("", "", "/");
    }
  }
  executeSearch() {
    this.speaches.currentSpeachPage = this.speaches.currentSpeachPage + 1;
    let requests = [];
    if (this.$refs.speachSearchResult !== undefined) {
      requests.push(this.searchSpeaches(this.searchTerm, this.speaches.currentSpeachPage, this.speaches.sort, this.speaches.langFilter));
    } else {
      requests.push(this.searchSpeaches(this.searchTerm, this.speaches.currentSpeachPage, this.speaches.sort));
    }
    if (this.searchUser) {
      //the first time we search for twice the number of users to have some loaded
      requests.push(this.searchUsers(this.searchTerm, this.users.numberOfUsersToDisplay * 2));
      //we have to pass a page because we went one page forward
      this.users.page++;
    }
    return axios.all(requests).then(axios.spread((speachSearchResponse, userSearchResponse) => {
      this.handleSpeachSearchResponse(speachSearchResponse);
      if (userSearchResponse) {
        this.handleUserSearchResponse(userSearchResponse, true);
      }
      this.searchFinished = true;
      this.empty = this.users.totalNumberOfUsersFound === 0 && this.speaches.totalNumberOfSpeachesFound === 0;
    }));
  }
  searchUsers(term, count) {
    return axios.get("/api/v2/users", {
      cancelToken: this.axiosCancelTokenSource.token,
      params: {
        dataType: "json",
        term: term,
        count: count,
        page: this.users.page
      }
    });
  }
  handleUserSearchResponse(userSearchResponse, searchChanged) {
    this.users.totalNumberOfUsersFound = userSearchResponse.data.totalNumberOfUsersFound;
    const usersFound = userSearchResponse.data.results;
    if (searchChanged) {
      this.users.usersFound = usersFound;
    } else {
      Array.prototype.push.apply(this.users.usersFound, usersFound);
    }
  }
  changeSpeachSort(sort) {
    this.speaches.sort = sort;
    this.filterSearch();
  }
  changeSpeachLangFilter(filter) {
    this.speaches.langFilter = filter;
    this.filterSearch();
  }
  searchSpeaches(term, page, sort, langFilter = null) {
    return axios.get("/api/v2/speach/search", {
      cancelToken: this.axiosCancelTokenSource.token,
      params: {
        dataType: "json",
        term: term,
        page: page,
        tri: sort,
        languageId: langFilter
      }
    });
  }
  handleSpeachSearchResponse(speachSearchResponse) {
    this.speaches.totalNumberOfSpeachesFound = speachSearchResponse.data.totalNumberOfSpeachesFound;
    this.speaches.availableLanguages = speachSearchResponse.data.availableLanguages;
    let newSpeachesFromsearch = speachSearchResponse.data.results;
    if (this.speaches.currentSpeachPage === 1) {
      // reset infinite loading when showing page 1 and component already exist
      if (this.$refs.speachSearchResult &&
      // @ts-ignore... seems weird
      this.$refs.speachSearchResult.$refs.infiniteLoader) {
        // @ts-ignore...
        this.$refs.speachSearchResult.resetInfiniteLoader();
      }
      this.speaches.speachesFound = newSpeachesFromsearch;
    } else {
      this.speaches.speachesFound = this.speaches.speachesFound.concat(newSpeachesFromsearch);
    }
  }
  closeSearch() {
    this.overlayOpen = false;
    this.speaches.speachesFound = [];
    this.speaches.totalNumberOfSpeachesFound = 0;
    this.speaches.currentSpeachPage = 0;
    this.users.usersFound = [];
    this.users.totalNumberOfUsersFound = 0;
    this.speaches.sort = SEARCH_SORT.RELEVANT;
  }
  getAuthorInfos(speachSearchResult) {
    let authorInfos = speachSearchResult.authorsInfos.author.label;
    // TODO weird...
    let stringuser = sessionStorage.getItem("user");
    if (stringuser) {
      let user = JSON.parse(stringuser);
      if (user) {
        if (speachSearchResult.authorId === user.id) {
          authorInfos += " ( " + this.$t("searchbar.userisspeachauthor") + " )";
        }
      }
    }
    return authorInfos;
  }
  getCoEditors(speachSearchResult) {
    return speachSearchResult.authorsInfos.coEditors.map(coEditor => {
      return {
        title: coEditor.label,
        imgUrl: coEditor.imgUrl
      };
    });
  }
  getCoEditorGroups(speachSearchResult) {
    return speachSearchResult.authorsInfos.coEditorGroups.map(group => {
      return {
        title: group.label,
        imgUrl: group.imgUrl
      };
    });
  }
  scrollToTop() {
    let container = this.$el.querySelector(".searchOverlay");
    container.scrollTop = 0;
  }
  close() {
    // @ts-ignore
    this.$refs.searchField.resetSearchTerm();
  }
  onLoadNewPageUsers() {
    this.searchFinished = false;
    this.users.page++;
    this.searchUsers(this.searchTerm, this.users.numberOfUsersToDisplay).then(userSearchResponse => {
      this.handleUserSearchResponse(userSearchResponse, false);
      this.searchFinished = true;
    });
  }
};
__decorate([Prop({
  default: false
})], SearchBar.prototype, "searchUser", void 0);
__decorate([Prop({
  default: false
})], SearchBar.prototype, "workflowEnabled", void 0);
SearchBar = __decorate([Component({
  components: {
    ImageBackground,
    InfiniteLoading,
    SpeachSearchResult,
    Loader,
    Icon,
    GridLayout,
    SearchBarSearchField,
    UserSearchResult
  }
})], SearchBar);
export default SearchBar;