import { ElMessage } from "element-plus";
import { h } from "vue";
const newLineTag = '<br/>';
export default class WElMessage {
  static showSuccessMessage(message) {
    ElMessage.success(message);
  }
  static showErrorMessage(message) {
    if (!message.includes(newLineTag)) {
      ElMessage.error(message);
    } else {
      this.showMultiLinesErrorMsg(message);
    }
  }
  static showLongErrorMessage(message) {
    if (!message.includes(newLineTag)) {
      ElMessage({
        message: message,
        duration: 0,
        showClose: true,
        type: 'error'
      });
    } else {
      this.showMultiLinesErrorMsg(message);
    }
  }
  static showMultiLinesErrorMsg(message) {
    const msgChildren = message.split(newLineTag).map(line => h('div', null, line));
    ElMessage({
      message: h('p', {
        class: 'message-color-danger'
      }, msgChildren),
      type: 'error'
    });
  }
  static showInfoMessage(message) {
    ElMessage.info(message);
  }
}