import {createApp} from "vue";
import { createI18n } from 'vue-i18n'
import GetStartedBlock from "@/components/getStartedBlock/GetStartedBlock.vue";
import SearchBar from "@/components/search/SearchBar.vue";
import SpeachSlider from "@/components/speachSlider/SpeachSlider.vue"
import SpeachGrid from "@/components/speachGrid/SpeachGrid.vue"
import NotificationHeaderApp from "@/components/notifications/NotificationHeaderApp.vue";
import NotificationPageApp from "@/views/notification/NotificationPageApp.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue"
import Loader from "@/components/loader/Loader.vue"
import MyAccount from "@/views/myAccount/MyAccount.vue"
import { ObserveVisibility } from 'vue-observe-visibility'
import Grid from "@/components/grid/Grid.vue"
import EditVideoChapter from "@/components/chapter/EditVideoChapter.vue"
import Footer from "@/components/footer/Footer.vue"
import AccessibilityStatementVue from "@/views/accessibility/AccessibilityStatementVue.vue"
import AccessibilityStatementEditVue from "@/views/accessibility/AccessibilityStatementEditVue.vue"
import ShareChapterWithSelector from "@/components/chapter/ShareChapterWithSelector.vue"
// @ts-ignore
import Cookies from 'js-cookie'

import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import de from '@/locales/de.json';
import es from '@/locales/es.json';
import "@/scss/global.scss";
import "@/types/legacyGlobals";
import {Events} from "@/events/events";
import GcuAgreementScreen from "@/views/gcu/GcuAgreementScreen.vue";
import GcuConsultScreen from "@/views/gcu/GcuConsultScreen.vue";
import PdpAgreementScreen from "@/views/pdp/PdpAgreementScreen.vue";
import PdpConsultScreen from "@/views/pdp/PdpConsultScreen.vue";
import WorkflowGraph from "@/components/workflow/WorkflowGraph.vue";
import EditDocumentChapter from "@/components/editDocumentChapter/EditDocumentChapter.vue";
import ResetQuizButton from "@/components/quiz/ResetQuizButton.vue";
import ExportMassScorm from "@/views/exportMassScorm/ExportMassScorm.vue";
import ExportCsv from "@/views/exportCsv/ExportCsv.vue";
import PathEditor from "@/views/pathEditor/PathEditor.vue";
import axios from "axios";
import {redirectIfLogin} from "@/util/loginUtils";
import AdminListUsers from "@/views/admin/users/AdminListUsers.vue";
import ConvertQuizToExamLink from "@/components/quiz/ConvertQuizToExamLink.vue";
import AdminListSpeaches from "@/views/admin/speaches/AdminListSpeaches.vue";
import AudioTrackLoader from "@/components/video/AudioTrackLoader.vue";
import AlertDialog from "@/components/dialogs/AlertDialog.vue";
import InformationDialog from "@/components/dialogs/NotificationDialog.vue";
import UnsubscribeEmailNotificationSuccess from "@/views/notification/UnsubscribeEmailNotificationSuccess.vue";
import DueDateSettings from "@/views/speach/DueDateSettings.vue";
import WeetChapter from "@/views/recorder/WeetChapter.vue";
import WeetPlayerChapter from "@/views/player/WeetPlayerChapter.vue";
import AIAdminPanel from "@/views/aiAdminPanel/AIAdminPanel.vue";


const i18n = createI18n({
    globalInjection: false,
    locale: Cookies.get("PLAY_LANG"),
    fallbackLocale: 'en',
    messages: {
        fr: fr,
        en: en,
        de: de,
        es:es
    }}
)
// createApp(App).mount('#app')

const mountElement = (el: Element) => {
    const app = createApp({})
    app.use(i18n);
    app.directive("observe-visibility", {
        beforeMount: (el, binding, vnode) => {
            (vnode as any).context = binding.instance
            ObserveVisibility.bind(el, binding, vnode)
        },
        updated: ObserveVisibility.update,
        unmounted: ObserveVisibility.unbind,
    });

    // This directive allows to add an action when clicking outside a coomponent
    app.directive('click-outside', {
      beforeMount: (el, binding) => {
        //  check that click was outside the el and his children
        el.clickOutsideEvent = (event: { target: any; }) => {
          const sidePanelFilterArray = Array.from(document.querySelectorAll('.sidepanel-click-filter'));
          const clickedInsideSidePanel = sidePanelFilterArray.length && (sidePanelFilterArray.some((filteredElement)=>filteredElement===event.target|| filteredElement.contains(event.target)));

          if (!(el == event.target || el.contains(event.target) || clickedInsideSidePanel)) {
            binding.value(event, el);
          }
        };
        document.addEventListener("mousedown", el.clickOutsideEvent);
      },
      unmounted: el => {
        document.removeEventListener("mousedown", el.clickOutsideEvent);
      },
    });

    // component
    app.component("get-started-block", GetStartedBlock);
    app.component("search-bar",SearchBar);
    app.component("notification-header-app",NotificationHeaderApp)
    app.component("reset-quiz-button", ResetQuizButton)
    app.component("loader",Loader)
    app.component("speach-slider",SpeachSlider)
    app.component("speach-grid",SpeachGrid);
    app.component("grid",Grid)
    app.component("edit-video-chapter",EditVideoChapter)
    app.component("edit-document-chapter",EditDocumentChapter)
    app.component("workflow-graph",WorkflowGraph)
    app.component('audioTrackLoader',AudioTrackLoader)
    app.component("convert-quiz-to-exam-link", ConvertQuizToExamLink)
    app.component("alert-dialog", AlertDialog)
    app.component("information-dialog", InformationDialog)
    /// view
    app.component("notification-page-app",NotificationPageApp);
    app.component("unsubscribe-email-notification-success",UnsubscribeEmailNotificationSuccess);
    app.component("dashboard",Dashboard);
    app.component("my-account-view",MyAccount)
    app.component("gcu-agreement-screen",GcuAgreementScreen)
    app.component("gcu-consult-screen",GcuConsultScreen)
    app.component("pdp-agreement-screen",PdpAgreementScreen)
    app.component("pdp-consult-screen",PdpConsultScreen)
    app.component("export-mass-scorm",ExportMassScorm);
    app.component("export-csv", ExportCsv);
    app.component("app-footer", Footer);
    app.component("path-editor", PathEditor);
    app.component("accessibility-statement-vue", AccessibilityStatementVue);
    app.component("accessibility-statement-edit-vue", AccessibilityStatementEditVue);
    app.component("admin-list-users", AdminListUsers);
    app.component("admin-list-speaches", AdminListSpeaches);
    app.component("weet-chapter",WeetChapter);
    app.component("weet-player-chapter",WeetPlayerChapter);
    app.component("due-date-settings", DueDateSettings);
    app.component("ai-admin-panel",AIAdminPanel);
    app.component("share-chapter-with-selector", ShareChapterWithSelector);

  app.mount(el);
}

const vueDomNodeToMountClass = "vueDomNodeToMount";
const vueDomNodeMountedClass = "vueDomNodeMounted";

/**
 * @param {Object} data send by speach-core (not use)
 * @param {Object} help send by speach-core (not use)
 * @param {String} clazz "class to mount"
 */
const mountVueComponent = (clazz = vueDomNodeToMountClass) => {
    const elements = document.querySelectorAll("." + clazz);

    for (const el of elements) {
        try {

            mountElement(el)
            el.classList.remove(clazz);
            el.classList.add(vueDomNodeMountedClass);
        } catch (e) {
            console.warn("Error on register this component " + el.id);
        }
    }
}
mountVueComponent();

axios.interceptors.response.use((response) => {
  redirectIfLogin(response.data);
  return response;
}, (error) => {
  console.error('Axios Error : ', error);
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

// todo found other solution to remove jquery
// @ts-ignore
$("body").off(Events.MOUNT_VUE_COMPONENT).on(Events.MOUNT_VUE_COMPONENT,(event,data)=>{
    mountVueComponent(data);
});
